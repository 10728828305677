/**
 * @fileoverview gRPC-Web generated client stub for deal_status_comment_template
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/deal_status_comment_template/deal_status_comment_template.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_deal_status_comment_template_deal_status_comment_template_pb from '../../protobuf/deal_status_comment_template/deal_status_comment_template_pb';
import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';


export class DealStatusCommentTemplateServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/deal_status_comment_template.DealStatusCommentTemplateService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.CreateDealStatusCommentTemplateRequest,
    protobuf_model_merged_pb.DealStatusCommentTemplateResponse,
    (request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.CreateDealStatusCommentTemplateRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.DealStatusCommentTemplateResponse.deserializeBinary
  );

  create(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.CreateDealStatusCommentTemplateRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.DealStatusCommentTemplateResponse>;

  create(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.CreateDealStatusCommentTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.DealStatusCommentTemplateResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.DealStatusCommentTemplateResponse>;

  create(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.CreateDealStatusCommentTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.DealStatusCommentTemplateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_comment_template.DealStatusCommentTemplateService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_comment_template.DealStatusCommentTemplateService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/deal_status_comment_template.DealStatusCommentTemplateService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.UpdateDealStatusCommentTemplateRequest,
    protobuf_model_merged_pb.DealStatusCommentTemplateResponse,
    (request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.UpdateDealStatusCommentTemplateRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.DealStatusCommentTemplateResponse.deserializeBinary
  );

  update(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.UpdateDealStatusCommentTemplateRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.DealStatusCommentTemplateResponse>;

  update(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.UpdateDealStatusCommentTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.DealStatusCommentTemplateResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.DealStatusCommentTemplateResponse>;

  update(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.UpdateDealStatusCommentTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.DealStatusCommentTemplateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_comment_template.DealStatusCommentTemplateService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_comment_template.DealStatusCommentTemplateService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/deal_status_comment_template.DealStatusCommentTemplateService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesRequest,
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesResponse,
    (request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesResponse.deserializeBinary
  );

  get(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesResponse>;

  get(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesResponse>;

  get(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_comment_template_deal_status_comment_template_pb.GetDealStatusCommentTemplatesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_comment_template.DealStatusCommentTemplateService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_comment_template.DealStatusCommentTemplateService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/deal_status_comment_template.DealStatusCommentTemplateService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesRequest,
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesResponse,
    (request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesResponse.deserializeBinary
  );

  delete(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesResponse>;

  delete(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesResponse>;

  delete(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_comment_template_deal_status_comment_template_pb.DeleteDealStatusCommentTemplatesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_comment_template.DealStatusCommentTemplateService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_comment_template.DealStatusCommentTemplateService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/deal_status_comment_template.DealStatusCommentTemplateService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesRequest,
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesResponse,
    (request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesResponse.deserializeBinary
  );

  list(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesResponse>;

  list(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesResponse>;

  list(
    request: protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_comment_template_deal_status_comment_template_pb.ListDealStatusCommentTemplatesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_comment_template.DealStatusCommentTemplateService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_comment_template.DealStatusCommentTemplateService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

}

