/**
 * @fileoverview gRPC-Web generated client stub for deal_status_change_reason
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/deal_status_change_reason/deal_status_change_reason.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_deal_status_change_reason_deal_status_change_reason_pb from '../../protobuf/deal_status_change_reason/deal_status_change_reason_pb';


export class DealStatusChangeReasonServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/deal_status_change_reason.DealStatusChangeReasonService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.CreateDealStatusChangeReasonRequest,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse,
    (request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.CreateDealStatusChangeReasonRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse.deserializeBinary
  );

  create(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.CreateDealStatusChangeReasonRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse>;

  create(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.CreateDealStatusChangeReasonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse>;

  create(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.CreateDealStatusChangeReasonRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_change_reason.DealStatusChangeReasonService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_change_reason.DealStatusChangeReasonService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/deal_status_change_reason.DealStatusChangeReasonService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.UpdateDealStatusChangeReasonRequest,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse,
    (request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.UpdateDealStatusChangeReasonRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse.deserializeBinary
  );

  update(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.UpdateDealStatusChangeReasonRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse>;

  update(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.UpdateDealStatusChangeReasonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse>;

  update(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.UpdateDealStatusChangeReasonRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.DealStatusChangeReasonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_change_reason.DealStatusChangeReasonService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_change_reason.DealStatusChangeReasonService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/deal_status_change_reason.DealStatusChangeReasonService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsRequest,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsResponse,
    (request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsResponse.deserializeBinary
  );

  get(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsResponse>;

  get(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsResponse>;

  get(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.GetDealStatusChangeReasonsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_change_reason.DealStatusChangeReasonService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_change_reason.DealStatusChangeReasonService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/deal_status_change_reason.DealStatusChangeReasonService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsRequest,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsResponse,
    (request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsResponse.deserializeBinary
  );

  delete(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsResponse>;

  delete(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsResponse>;

  delete(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.DeleteDealStatusChangeReasonsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_change_reason.DealStatusChangeReasonService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_change_reason.DealStatusChangeReasonService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/deal_status_change_reason.DealStatusChangeReasonService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsRequest,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsResponse,
    (request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsResponse.deserializeBinary
  );

  list(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsResponse>;

  list(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsResponse>;

  list(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ListDealStatusChangeReasonsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_change_reason.DealStatusChangeReasonService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_change_reason.DealStatusChangeReasonService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/deal_status_change_reason.DealStatusChangeReasonService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsRequest,
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsResponse,
    (request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsResponse.deserializeBinary
  );

  export(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsResponse>;

  export(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsResponse>;

  export(
    request: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_status_change_reason_deal_status_change_reason_pb.ExportDealStatusChangeReasonsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal_status_change_reason.DealStatusChangeReasonService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal_status_change_reason.DealStatusChangeReasonService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

