// source: protobuf/deal_status_comment/deal_status_comment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var protobuf_filter_counters_pb = require('../../protobuf/filter/counters_pb.js');
goog.object.extend(proto, protobuf_filter_counters_pb);
var protobuf_filter_request_pb = require('../../protobuf/filter/request_pb.js');
goog.object.extend(proto, protobuf_filter_request_pb);
var protobuf_validate_validate_pb = require('../../protobuf/validate/validate_pb.js');
goog.object.extend(proto, protobuf_validate_validate_pb);
var protobuf_model_merged_pb = require('../../protobuf/model/merged_pb.js');
goog.object.extend(proto, protobuf_model_merged_pb);
goog.exportSymbol('proto.deal_status_comment.CreateDealStatusCommentRequest', null, global);
goog.exportSymbol('proto.deal_status_comment.DealStatusCommentRecipientRequest', null, global);
goog.exportSymbol('proto.deal_status_comment.DealStatusCommentRecipientResponse', null, global);
goog.exportSymbol('proto.deal_status_comment.DeleteDealStatusCommentsRequest', null, global);
goog.exportSymbol('proto.deal_status_comment.DeleteDealStatusCommentsResponse', null, global);
goog.exportSymbol('proto.deal_status_comment.GetDealStatusCommentsRequest', null, global);
goog.exportSymbol('proto.deal_status_comment.GetDealStatusCommentsResponse', null, global);
goog.exportSymbol('proto.deal_status_comment.ListDealStatusCommentsRequest', null, global);
goog.exportSymbol('proto.deal_status_comment.ListDealStatusCommentsResponse', null, global);
goog.exportSymbol('proto.deal_status_comment.UpdateDealStatusCommentRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal_status_comment.DeleteDealStatusCommentsRequest.repeatedFields_, null);
};
goog.inherits(proto.deal_status_comment.DeleteDealStatusCommentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_comment.DeleteDealStatusCommentsRequest.displayName = 'proto.deal_status_comment.DeleteDealStatusCommentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_comment.DeleteDealStatusCommentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_comment.DeleteDealStatusCommentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_comment.DeleteDealStatusCommentsResponse.displayName = 'proto.deal_status_comment.DeleteDealStatusCommentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_comment.CreateDealStatusCommentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal_status_comment.CreateDealStatusCommentRequest.repeatedFields_, null);
};
goog.inherits(proto.deal_status_comment.CreateDealStatusCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_comment.CreateDealStatusCommentRequest.displayName = 'proto.deal_status_comment.CreateDealStatusCommentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_comment.DealStatusCommentRecipientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_comment.DealStatusCommentRecipientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_comment.DealStatusCommentRecipientRequest.displayName = 'proto.deal_status_comment.DealStatusCommentRecipientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_comment.DealStatusCommentRecipientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_comment.DealStatusCommentRecipientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_comment.DealStatusCommentRecipientResponse.displayName = 'proto.deal_status_comment.DealStatusCommentRecipientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal_status_comment.UpdateDealStatusCommentRequest.repeatedFields_, null);
};
goog.inherits(proto.deal_status_comment.UpdateDealStatusCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_comment.UpdateDealStatusCommentRequest.displayName = 'proto.deal_status_comment.UpdateDealStatusCommentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_comment.GetDealStatusCommentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal_status_comment.GetDealStatusCommentsRequest.repeatedFields_, null);
};
goog.inherits(proto.deal_status_comment.GetDealStatusCommentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_comment.GetDealStatusCommentsRequest.displayName = 'proto.deal_status_comment.GetDealStatusCommentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_comment.GetDealStatusCommentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_comment.GetDealStatusCommentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_comment.GetDealStatusCommentsResponse.displayName = 'proto.deal_status_comment.GetDealStatusCommentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_comment.ListDealStatusCommentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_comment.ListDealStatusCommentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_comment.ListDealStatusCommentsRequest.displayName = 'proto.deal_status_comment.ListDealStatusCommentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_comment.ListDealStatusCommentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal_status_comment.ListDealStatusCommentsResponse.repeatedFields_, null);
};
goog.inherits(proto.deal_status_comment.ListDealStatusCommentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_comment.ListDealStatusCommentsResponse.displayName = 'proto.deal_status_comment.ListDealStatusCommentsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_comment.DeleteDealStatusCommentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_comment.DeleteDealStatusCommentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_comment.DeleteDealStatusCommentsRequest}
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_comment.DeleteDealStatusCommentsRequest;
  return proto.deal_status_comment.DeleteDealStatusCommentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_comment.DeleteDealStatusCommentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_comment.DeleteDealStatusCommentsRequest}
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_comment.DeleteDealStatusCommentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_comment.DeleteDealStatusCommentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal_status_comment.DeleteDealStatusCommentsRequest} returns this
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal_status_comment.DeleteDealStatusCommentsRequest} returns this
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal_status_comment.DeleteDealStatusCommentsRequest} returns this
 */
proto.deal_status_comment.DeleteDealStatusCommentsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_comment.DeleteDealStatusCommentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_comment.DeleteDealStatusCommentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_comment.DeleteDealStatusCommentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.DeleteDealStatusCommentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_comment.DeleteDealStatusCommentsResponse}
 */
proto.deal_status_comment.DeleteDealStatusCommentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_comment.DeleteDealStatusCommentsResponse;
  return proto.deal_status_comment.DeleteDealStatusCommentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_comment.DeleteDealStatusCommentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_comment.DeleteDealStatusCommentsResponse}
 */
proto.deal_status_comment.DeleteDealStatusCommentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_comment.DeleteDealStatusCommentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_comment.DeleteDealStatusCommentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_comment.DeleteDealStatusCommentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.DeleteDealStatusCommentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_comment.CreateDealStatusCommentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_comment.CreateDealStatusCommentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    locationLat: jspb.Message.getFieldWithDefault(msg, 4, ""),
    locationLng: jspb.Message.getFieldWithDefault(msg, 5, ""),
    locationLabel: jspb.Message.getFieldWithDefault(msg, 6, ""),
    remdistKm: jspb.Message.getFieldWithDefault(msg, 7, ""),
    remdistLabel: jspb.Message.getFieldWithDefault(msg, 8, ""),
    recipientsList: jspb.Message.toObjectList(msg.getRecipientsList(),
    proto.deal_status_comment.DealStatusCommentRecipientRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_comment.CreateDealStatusCommentRequest;
  return proto.deal_status_comment.CreateDealStatusCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_comment.CreateDealStatusCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLng(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLabel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemdistKm(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemdistLabel(value);
      break;
    case 9:
      var value = new proto.deal_status_comment.DealStatusCommentRecipientRequest;
      reader.readMessage(value,proto.deal_status_comment.DealStatusCommentRecipientRequest.deserializeBinaryFromReader);
      msg.addRecipients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_comment.CreateDealStatusCommentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_comment.CreateDealStatusCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocationLat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocationLng();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLocationLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRemdistKm();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRemdistLabel();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.deal_status_comment.DealStatusCommentRecipientRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string location_lat = 4;
 * @return {string}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.getLocationLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.setLocationLat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string location_lng = 5;
 * @return {string}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.getLocationLng = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.setLocationLng = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string location_label = 6;
 * @return {string}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.getLocationLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.setLocationLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string remdist_km = 7;
 * @return {string}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.getRemdistKm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.setRemdistKm = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string remdist_label = 8;
 * @return {string}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.getRemdistLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.setRemdistLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated DealStatusCommentRecipientRequest recipients = 9;
 * @return {!Array<!proto.deal_status_comment.DealStatusCommentRecipientRequest>}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.getRecipientsList = function() {
  return /** @type{!Array<!proto.deal_status_comment.DealStatusCommentRecipientRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.deal_status_comment.DealStatusCommentRecipientRequest, 9));
};


/**
 * @param {!Array<!proto.deal_status_comment.DealStatusCommentRecipientRequest>} value
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest} returns this
*/
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.setRecipientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.deal_status_comment.DealStatusCommentRecipientRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.deal_status_comment.DealStatusCommentRecipientRequest}
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.addRecipients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.deal_status_comment.DealStatusCommentRecipientRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal_status_comment.CreateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.CreateDealStatusCommentRequest.prototype.clearRecipientsList = function() {
  return this.setRecipientsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_comment.DealStatusCommentRecipientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_comment.DealStatusCommentRecipientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_comment.DealStatusCommentRecipientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.DealStatusCommentRecipientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_comment.DealStatusCommentRecipientRequest}
 */
proto.deal_status_comment.DealStatusCommentRecipientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_comment.DealStatusCommentRecipientRequest;
  return proto.deal_status_comment.DealStatusCommentRecipientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_comment.DealStatusCommentRecipientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_comment.DealStatusCommentRecipientRequest}
 */
proto.deal_status_comment.DealStatusCommentRecipientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_comment.DealStatusCommentRecipientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_comment.DealStatusCommentRecipientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_comment.DealStatusCommentRecipientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.DealStatusCommentRecipientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_id = 1;
 * @return {string}
 */
proto.deal_status_comment.DealStatusCommentRecipientRequest.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.DealStatusCommentRecipientRequest} returns this
 */
proto.deal_status_comment.DealStatusCommentRecipientRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_comment.DealStatusCommentRecipientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_comment.DealStatusCommentRecipientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_comment.DealStatusCommentRecipientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.DealStatusCommentRecipientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_comment.DealStatusCommentRecipientResponse}
 */
proto.deal_status_comment.DealStatusCommentRecipientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_comment.DealStatusCommentRecipientResponse;
  return proto.deal_status_comment.DealStatusCommentRecipientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_comment.DealStatusCommentRecipientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_comment.DealStatusCommentRecipientResponse}
 */
proto.deal_status_comment.DealStatusCommentRecipientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_comment.DealStatusCommentRecipientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_comment.DealStatusCommentRecipientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_comment.DealStatusCommentRecipientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.DealStatusCommentRecipientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_id = 1;
 * @return {string}
 */
proto.deal_status_comment.DealStatusCommentRecipientResponse.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.DealStatusCommentRecipientResponse} returns this
 */
proto.deal_status_comment.DealStatusCommentRecipientResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_comment.UpdateDealStatusCommentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_comment.UpdateDealStatusCommentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    locationLat: jspb.Message.getFieldWithDefault(msg, 4, ""),
    locationLng: jspb.Message.getFieldWithDefault(msg, 5, ""),
    locationLabel: jspb.Message.getFieldWithDefault(msg, 6, ""),
    remdistKm: jspb.Message.getFieldWithDefault(msg, 7, ""),
    remdistLabel: jspb.Message.getFieldWithDefault(msg, 8, ""),
    recipientsList: jspb.Message.toObjectList(msg.getRecipientsList(),
    proto.deal_status_comment.DealStatusCommentRecipientRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_comment.UpdateDealStatusCommentRequest;
  return proto.deal_status_comment.UpdateDealStatusCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_comment.UpdateDealStatusCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLng(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLabel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemdistKm(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemdistLabel(value);
      break;
    case 9:
      var value = new proto.deal_status_comment.DealStatusCommentRecipientRequest;
      reader.readMessage(value,proto.deal_status_comment.DealStatusCommentRecipientRequest.deserializeBinaryFromReader);
      msg.addRecipients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_comment.UpdateDealStatusCommentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_comment.UpdateDealStatusCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocationLat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocationLng();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLocationLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRemdistKm();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRemdistLabel();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.deal_status_comment.DealStatusCommentRecipientRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string location_lat = 4;
 * @return {string}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.getLocationLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.setLocationLat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string location_lng = 5;
 * @return {string}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.getLocationLng = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.setLocationLng = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string location_label = 6;
 * @return {string}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.getLocationLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.setLocationLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string remdist_km = 7;
 * @return {string}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.getRemdistKm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.setRemdistKm = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string remdist_label = 8;
 * @return {string}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.getRemdistLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.setRemdistLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated DealStatusCommentRecipientRequest recipients = 9;
 * @return {!Array<!proto.deal_status_comment.DealStatusCommentRecipientRequest>}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.getRecipientsList = function() {
  return /** @type{!Array<!proto.deal_status_comment.DealStatusCommentRecipientRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.deal_status_comment.DealStatusCommentRecipientRequest, 9));
};


/**
 * @param {!Array<!proto.deal_status_comment.DealStatusCommentRecipientRequest>} value
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest} returns this
*/
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.setRecipientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.deal_status_comment.DealStatusCommentRecipientRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.deal_status_comment.DealStatusCommentRecipientRequest}
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.addRecipients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.deal_status_comment.DealStatusCommentRecipientRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal_status_comment.UpdateDealStatusCommentRequest} returns this
 */
proto.deal_status_comment.UpdateDealStatusCommentRequest.prototype.clearRecipientsList = function() {
  return this.setRecipientsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_comment.GetDealStatusCommentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_comment.GetDealStatusCommentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_comment.GetDealStatusCommentsRequest}
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_comment.GetDealStatusCommentsRequest;
  return proto.deal_status_comment.GetDealStatusCommentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_comment.GetDealStatusCommentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_comment.GetDealStatusCommentsRequest}
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_comment.GetDealStatusCommentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_comment.GetDealStatusCommentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal_status_comment.GetDealStatusCommentsRequest} returns this
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal_status_comment.GetDealStatusCommentsRequest} returns this
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal_status_comment.GetDealStatusCommentsRequest} returns this
 */
proto.deal_status_comment.GetDealStatusCommentsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_comment.GetDealStatusCommentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_comment.GetDealStatusCommentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_comment.GetDealStatusCommentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.GetDealStatusCommentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.model.DealStatusCommentResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_comment.GetDealStatusCommentsResponse}
 */
proto.deal_status_comment.GetDealStatusCommentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_comment.GetDealStatusCommentsResponse;
  return proto.deal_status_comment.GetDealStatusCommentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_comment.GetDealStatusCommentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_comment.GetDealStatusCommentsResponse}
 */
proto.deal_status_comment.GetDealStatusCommentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.model.DealStatusCommentResponse.deserializeBinaryFromReader, "", new proto.model.DealStatusCommentResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_comment.GetDealStatusCommentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_comment.GetDealStatusCommentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_comment.GetDealStatusCommentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.GetDealStatusCommentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.model.DealStatusCommentResponse.serializeBinaryToWriter);
  }
};


/**
 * map<string, model.DealStatusCommentResponse> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.model.DealStatusCommentResponse>}
 */
proto.deal_status_comment.GetDealStatusCommentsResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.model.DealStatusCommentResponse>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.model.DealStatusCommentResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.deal_status_comment.GetDealStatusCommentsResponse} returns this
 */
proto.deal_status_comment.GetDealStatusCommentsResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_comment.ListDealStatusCommentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_comment.ListDealStatusCommentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_comment.ListDealStatusCommentsRequest}
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_comment.ListDealStatusCommentsRequest;
  return proto.deal_status_comment.ListDealStatusCommentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_comment.ListDealStatusCommentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_comment.ListDealStatusCommentsRequest}
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_comment.ListDealStatusCommentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_comment.ListDealStatusCommentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_comment.ListDealStatusCommentsRequest} returns this
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional filter.Request filter = 2;
 * @return {?proto.filter.Request}
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 2));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.deal_status_comment.ListDealStatusCommentsRequest} returns this
*/
proto.deal_status_comment.ListDealStatusCommentsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal_status_comment.ListDealStatusCommentsRequest} returns this
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal_status_comment.ListDealStatusCommentsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_comment.ListDealStatusCommentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_comment.ListDealStatusCommentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    protobuf_model_merged_pb.DealStatusCommentResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_comment.ListDealStatusCommentsResponse}
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_comment.ListDealStatusCommentsResponse;
  return proto.deal_status_comment.ListDealStatusCommentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_comment.ListDealStatusCommentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_comment.ListDealStatusCommentsResponse}
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = new protobuf_model_merged_pb.DealStatusCommentResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DealStatusCommentResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_comment.ListDealStatusCommentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_comment.ListDealStatusCommentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      protobuf_model_merged_pb.DealStatusCommentResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.deal_status_comment.ListDealStatusCommentsResponse} returns this
*/
proto.deal_status_comment.ListDealStatusCommentsResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal_status_comment.ListDealStatusCommentsResponse} returns this
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated model.DealStatusCommentResponse items = 2;
 * @return {!Array<!proto.model.DealStatusCommentResponse>}
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.DealStatusCommentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.DealStatusCommentResponse, 2));
};


/**
 * @param {!Array<!proto.model.DealStatusCommentResponse>} value
 * @return {!proto.deal_status_comment.ListDealStatusCommentsResponse} returns this
*/
proto.deal_status_comment.ListDealStatusCommentsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.DealStatusCommentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DealStatusCommentResponse}
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.DealStatusCommentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal_status_comment.ListDealStatusCommentsResponse} returns this
 */
proto.deal_status_comment.ListDealStatusCommentsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


goog.object.extend(exports, proto.deal_status_comment);
