// source: protobuf/deal_status_change_reason/deal_status_change_reason.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var protobuf_validate_validate_pb = require('../../protobuf/validate/validate_pb.js');
goog.object.extend(proto, protobuf_validate_validate_pb);
var protobuf_filter_counters_pb = require('../../protobuf/filter/counters_pb.js');
goog.object.extend(proto, protobuf_filter_counters_pb);
var protobuf_filter_export_pb = require('../../protobuf/filter/export_pb.js');
goog.object.extend(proto, protobuf_filter_export_pb);
var protobuf_filter_request_pb = require('../../protobuf/filter/request_pb.js');
goog.object.extend(proto, protobuf_filter_request_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var protobuf_model_merged_pb = require('../../protobuf/model/merged_pb.js');
goog.object.extend(proto, protobuf_model_merged_pb);
goog.exportSymbol('proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest', null, global);
goog.exportSymbol('proto.deal_status_change_reason.DealStatusChangeReasonResponse', null, global);
goog.exportSymbol('proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest', null, global);
goog.exportSymbol('proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse', null, global);
goog.exportSymbol('proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest', null, global);
goog.exportSymbol('proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse', null, global);
goog.exportSymbol('proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest', null, global);
goog.exportSymbol('proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse', null, global);
goog.exportSymbol('proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest', null, global);
goog.exportSymbol('proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse', null, global);
goog.exportSymbol('proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.displayName = 'proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_change_reason.DealStatusChangeReasonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.DealStatusChangeReasonResponse.displayName = 'proto.deal_status_change_reason.DealStatusChangeReasonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.displayName = 'proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.repeatedFields_, null);
};
goog.inherits(proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.displayName = 'proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.displayName = 'proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.repeatedFields_, null);
};
goog.inherits(proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.displayName = 'proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse.displayName = 'proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.displayName = 'proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.repeatedFields_, null);
};
goog.inherits(proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.displayName = 'proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.displayName = 'proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.displayName = 'proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonTitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealStatusFrom: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest}
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest;
  return proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest}
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonTitle(value);
      break;
    case 2:
      var value = /** @type {!proto.model.DealStatus} */ (reader.readEnum());
      msg.setDealStatusFrom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealStatusFrom();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string reason_title = 1;
 * @return {string}
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.prototype.getReasonTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest} returns this
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.prototype.setReasonTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional model.DealStatus deal_status_from = 2;
 * @return {!proto.model.DealStatus}
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.prototype.getDealStatusFrom = function() {
  return /** @type {!proto.model.DealStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.DealStatus} value
 * @return {!proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest} returns this
 */
proto.deal_status_change_reason.CreateDealStatusChangeReasonRequest.prototype.setDealStatusFrom = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.DealStatusChangeReasonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reasonTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealStatusFrom: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 6, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.DealStatusChangeReasonResponse;
  return proto.deal_status_change_reason.DealStatusChangeReasonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.model.DealStatus} */ (reader.readEnum());
      msg.setDealStatusFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.DealStatusChangeReasonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReasonTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealStatusFrom();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} returns this
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reason_title = 2;
 * @return {string}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.getReasonTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} returns this
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.setReasonTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional model.DealStatus deal_status_from = 3;
 * @return {!proto.model.DealStatus}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.getDealStatusFrom = function() {
  return /** @type {!proto.model.DealStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.model.DealStatus} value
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} returns this
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.setDealStatusFrom = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} returns this
*/
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} returns this
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} returns this
*/
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} returns this
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string created_by_id = 6;
 * @return {string}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} returns this
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string updated_by_id = 7;
 * @return {string}
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse} returns this
 */
proto.deal_status_change_reason.DealStatusChangeReasonResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reasonTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealStatusFrom: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest}
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest;
  return proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest}
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.model.DealStatus} */ (reader.readEnum());
      msg.setDealStatusFrom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReasonTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealStatusFrom();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest} returns this
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reason_title = 2;
 * @return {string}
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.prototype.getReasonTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest} returns this
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.prototype.setReasonTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional model.DealStatus deal_status_from = 3;
 * @return {!proto.model.DealStatus}
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.prototype.getDealStatusFrom = function() {
  return /** @type {!proto.model.DealStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.model.DealStatus} value
 * @return {!proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest} returns this
 */
proto.deal_status_change_reason.UpdateDealStatusChangeReasonRequest.prototype.setDealStatusFrom = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest}
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest;
  return proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest}
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest} returns this
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest} returns this
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest} returns this
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.deal_status_change_reason.DealStatusChangeReasonResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse}
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse;
  return proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse}
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.deal_status_change_reason.DealStatusChangeReasonResponse.deserializeBinaryFromReader, "", new proto.deal_status_change_reason.DealStatusChangeReasonResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.deal_status_change_reason.DealStatusChangeReasonResponse.serializeBinaryToWriter);
  }
};


/**
 * map<string, DealStatusChangeReasonResponse> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.deal_status_change_reason.DealStatusChangeReasonResponse>}
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.deal_status_change_reason.DealStatusChangeReasonResponse>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.deal_status_change_reason.DealStatusChangeReasonResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse} returns this
 */
proto.deal_status_change_reason.GetDealStatusChangeReasonsResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest}
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest;
  return proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest}
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest} returns this
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest} returns this
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest} returns this
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse}
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse;
  return proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse}
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.DeleteDealStatusChangeReasonsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest;
  return proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest} returns this
*/
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest} returns this
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.deal_status_change_reason.DealStatusChangeReasonResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse;
  return proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = new proto.deal_status_change_reason.DealStatusChangeReasonResponse;
      reader.readMessage(value,proto.deal_status_change_reason.DealStatusChangeReasonResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.deal_status_change_reason.DealStatusChangeReasonResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse} returns this
*/
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse} returns this
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DealStatusChangeReasonResponse items = 2;
 * @return {!Array<!proto.deal_status_change_reason.DealStatusChangeReasonResponse>}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.deal_status_change_reason.DealStatusChangeReasonResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.deal_status_change_reason.DealStatusChangeReasonResponse, 2));
};


/**
 * @param {!Array<!proto.deal_status_change_reason.DealStatusChangeReasonResponse>} value
 * @return {!proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse} returns this
*/
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.deal_status_change_reason.DealStatusChangeReasonResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.deal_status_change_reason.DealStatusChangeReasonResponse}
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.deal_status_change_reason.DealStatusChangeReasonResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse} returns this
 */
proto.deal_status_change_reason.ListDealStatusChangeReasonsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest;
  return proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest} returns this
*/
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest} returns this
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_export: (f = msg.getExport()) && protobuf_filter_export_pb.ExportResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse;
  return proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_export_pb.ExportResponse;
      reader.readMessage(value,protobuf_filter_export_pb.ExportResponse.deserializeBinaryFromReader);
      msg.setExport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_export_pb.ExportResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.ExportResponse export = 1;
 * @return {?proto.filter.ExportResponse}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.prototype.getExport = function() {
  return /** @type{?proto.filter.ExportResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_export_pb.ExportResponse, 1));
};


/**
 * @param {?proto.filter.ExportResponse|undefined} value
 * @return {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse} returns this
*/
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.prototype.setExport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse} returns this
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.prototype.clearExport = function() {
  return this.setExport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal_status_change_reason.ExportDealStatusChangeReasonsResponse.prototype.hasExport = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.deal_status_change_reason);
